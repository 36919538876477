<template>
  <b-modal
    :id="`report-resell-ticket-modal`"
    size="md"
    centered
    no-close-on-backdrop
    header-bg-variant="light-info"
    hide-footer
    :title="$t('modal.confirmReport')"
    @show="handleShow"
  >
    <IAmOverlay :loading="false">
      <validation-observer ref="refFormObserver">
        <div class="d-flex flex-column">
          <span class="text-danger">{{ this.$t('resellTicket.report.warning1') }}</span>
          <span class="text-danger">{{ this.$t('resellTicket.report.warning2') }}</span>
          <ol>
            <li>{{ this.$t('resellTicket.report.report1') }}</li>
            <li>{{ this.$t('resellTicket.report.report2') }}</li>
          </ol>
        </div>
        <b-form>
          <b-row>
            <b-col md="12">
              <validation-provider
                #default="validationContext"
                :name="$t('resellTicket.form.phone')"
                vid="phone"
                rules="required"
              >
                <b-form-group>
                  <template #label>
                    {{ $t('resellTicket.form.phone') }}
                    <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    id="phone"
                    v-model="createReportReq.phone"
                    v-remove-non-numeric-chars.allNumber
                    :name="$t('resellTicket.form.phone')"
                    :state="getValidationState(validationContext) === false ? false : null"
                    :placeholder="$t('resellTicket.form.ph.phone')"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <validation-provider
                #default="validationContext"
                :name="$t('resellTicket.form.ph.reason')"
                vid="reason"
                rules="required"
              >
                <b-form-group label-for="reason">
                  <template #label>
                    {{ $t('resellTicket.form.reason') }}
                    <span class="text-danger">*</span>
                  </template>
                  <b-form-textarea
                    id="reason"
                    v-model="createReportReq.reason"
                    :placeholder="$t('resellTicket.form.ph.reason')"
                    :name="$t('resellTicket.form.ph.reason')"
                    :state="getValidationState(validationContext) === false ? false : null"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-form>
        <div class="d-flex-center gap-3">
          <b-button
            variant="info"
            @click="handleSubmit"
          >
            {{ $t('save') }}
          </b-button>
          <b-button
            variant="secondary"
            @click="handleCancel"
          >
            {{ $t('cancel') }}
          </b-button>
        </div>
      </validation-observer>
    </IAmOverlay>
  </b-modal>

</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BModal,
  BRow,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import cloneDeep from 'lodash/cloneDeep'

import formValidation from '@core/comp-functions/forms/form-validation'

import useResellTicketHandle from '@saleReport/useResellTicketHandle'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormInvalidFeedback,
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
    BButton,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    id: {
      type: [Number, null],
      default: null,
    },
  },
  setup(props, { emit }) {
    const req = {
      phone: null,
      reason: null,
    }
    const { refFormObserver, getValidationState } = formValidation()

    const createReportReq = ref(cloneDeep(req))
    const { createReport } = useResellTicketHandle()
    // methods
    function handleCancel() {
      createReportReq.value = ref(cloneDeep(req))
      this.$bvModal.hide('report-resell-ticket-modal')
    }
    function handleSubmit(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.$refs.refFormObserver.validate().then(async success => {
        if (success) {
          this.$bvModal.show('modal-api-loading')
          await createReport(props.id, createReportReq.value)
          emit('refresh')
          this.$bvModal.hide('modal-api-loading')
          this.handleCancel()
        }
      })
    }
    function handleShow() {
      createReportReq.value = cloneDeep(req)
    }

    return {
      createReportReq,
      handleCancel,
      handleSubmit,
      refFormObserver,
      getValidationState,
      handleShow,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
