var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "report-resell-ticket-modal",
      "size": "md",
      "centered": "",
      "no-close-on-backdrop": "",
      "header-bg-variant": "light-info",
      "hide-footer": "",
      "title": _vm.$t('modal.confirmReport')
    },
    on: {
      "show": _vm.handleShow
    }
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": false
    }
  }, [_c('validation-observer', {
    ref: "refFormObserver"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('span', {
    staticClass: "text-danger"
  }, [_vm._v(_vm._s(this.$t('resellTicket.report.warning1')))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v(_vm._s(this.$t('resellTicket.report.warning2')))]), _c('ol', [_c('li', [_vm._v(_vm._s(this.$t('resellTicket.report.report1')))]), _c('li', [_vm._v(_vm._s(this.$t('resellTicket.report.report2')))])])]), _c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('resellTicket.form.phone'),
      "vid": "phone",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('resellTicket.form.phone')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("*")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-input', {
          directives: [{
            name: "remove-non-numeric-chars",
            rawName: "v-remove-non-numeric-chars.allNumber",
            modifiers: {
              "allNumber": true
            }
          }],
          attrs: {
            "id": "phone",
            "name": _vm.$t('resellTicket.form.phone'),
            "state": _vm.getValidationState(validationContext) === false ? false : null,
            "placeholder": _vm.$t('resellTicket.form.ph.phone')
          },
          model: {
            value: _vm.createReportReq.phone,
            callback: function callback($$v) {
              _vm.$set(_vm.createReportReq, "phone", $$v);
            },
            expression: "createReportReq.phone"
          }
        }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
      }
    }])
  })], 1), _c('b-col', [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('resellTicket.form.ph.reason'),
      "vid": "reason",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_c('b-form-group', {
          attrs: {
            "label-for": "reason"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('resellTicket.form.reason')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("*")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-textarea', {
          attrs: {
            "id": "reason",
            "placeholder": _vm.$t('resellTicket.form.ph.reason'),
            "name": _vm.$t('resellTicket.form.ph.reason'),
            "state": _vm.getValidationState(validationContext) === false ? false : null
          },
          model: {
            value: _vm.createReportReq.reason,
            callback: function callback($$v) {
              _vm.$set(_vm.createReportReq, "reason", $$v);
            },
            expression: "createReportReq.reason"
          }
        }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
      }
    }])
  })], 1)], 1)], 1), _c('div', {
    staticClass: "d-flex-center gap-3"
  }, [_c('b-button', {
    attrs: {
      "variant": "info"
    },
    on: {
      "click": _vm.handleSubmit
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('save')) + " ")]), _c('b-button', {
    attrs: {
      "variant": "secondary"
    },
    on: {
      "click": _vm.handleCancel
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('cancel')) + " ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }